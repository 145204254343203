import { Fragment, useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchHolidays, holidaysInit, putHolidays } from '../../store/actions';
import Toolbar from '../../components/toolbar/Toolbar';

import LoadingSpinner from '../../components/UI/LoadingSpinner';

import { RootState } from '../..';

import classNames from "classnames/bind";
import classes from './Holidays.module.scss';
import { employeeReducer, generateArrayOfYears } from '../../lib/Utils';
import { useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BasicSelect from '../../components/UI/BasicSelect';
import { OptionType } from '../../types/Props';
import BasicTextField from '../../components/UI/BasicInput';
import HolidayList from '../../components/holiday/HolidayList';
import { SelectChangeEvent } from '@mui/material';
import useHttp from '../../hooks/use-http';
import { getEmployees } from '../../lib/api';

const cx = classNames.bind(classes);

const Holidays: React.FC = () => {
  const [anios, setAnios] = useState([]);
  const {rol, enterprise } = useSelector((state: RootState) => state.auth);

  const { pathname } = useLocation();
  const isManager = pathname.endsWith('holidays/manager');
  
  const dispatch = useDispatch();
  const { part, loading, error } = useSelector((state: RootState) => state.holidays);

  const [userState, dispatchEmployee] = useReducer(employeeReducer, {
    employee: '', name: '', month: null, year: (new Date()).getFullYear()
  });

  const { sendRequest, data: employees, error1 } = useHttp(
    getEmployees, true
  );

  useEffect(() => {
    setAnios(generateArrayOfYears());
    let d = new Date();
    let stUser = localStorage.getItem("user");
    let user = JSON.parse(stUser!);
    // Employees, if manager user
    if (isManager) {
      sendRequest({manager: user.employee, year: d.getFullYear(), month: d.getMonth() + 1, rol, enterprise});
      dispatch(holidaysInit(user.employee, d.getFullYear()));
      dispatchEmployee({ type: 'USER_CHANGE', employee: null, name: null, year: d.getFullYear(), month: null });
      return;
    }
    
    // valores por defecto
    const employee = user.employee;
    const name = user.name;
    const month = d.getMonth();
    dispatchEmployee({ type: 'USER_CHANGE', employee, name, year: d.getFullYear(), month });

    // Holidays
    dispatch(fetchHolidays('token!!!', employee, d.getFullYear()));

  }, [isManager, dispatch, sendRequest]);

  
  const onChangeYearHandler = (event:SelectChangeEvent<string>) => {
    let year = parseInt(event.target.value);
    dispatchEmployee({ type: 'USER_CHANGE', 
      employee: userState.employee, name: userState.name, year, month: userState.month });
    if (year && userState.employee) {
      dispatch(fetchHolidays('token!!!', userState.employee, year));
    }
  }

  const onChangeEmployee = (event:SelectChangeEvent<string>) => {
    let aux2 = event.target.value.split('@');
    if (!aux2 || aux2.length < 2) {
      dispatchEmployee({ type: 'USER_CHANGE', 
        employee: '', name: '', year: userState.year, month: userState.month });
      return;
    }
    let employee = parseInt(aux2[0]);
    let name = aux2[1];
    dispatchEmployee({ type: 'USER_CHANGE', 
      employee, name, year: userState.year, month: userState.month });
    if (userState.year && employee) {
      dispatch(fetchHolidays('token!!!', employee, userState.year));
    }
  }


  const onSaveHandler = () => {
    dispatch(putHolidays('token!!!', part, 'G'));
  };

  const onSendHandler = () => {
    dispatch(putHolidays('token!!!', part, 'E'));
  };

  const onCancelHandler = () => {
    dispatch(fetchHolidays('token!!!', part.employee, part.year));
  };

  if (loading) {
    return (
      <div className='centered'>
        <LoadingSpinner />
      </div>
    );
  }

  let employeesList: OptionType[] = employees;
  if (!isManager || !employees) {
    let obj = new OptionType();
    obj.value = userState.employee + '@' + userState.name;
    obj.label = userState.name;
    employeesList = [];
    employeesList.push(obj);
  }

  return (
  <Fragment>

  <div className='mt-4'/>

  <Grid container spacing={2}>
  <Grid item xs={12}sm={6} md={3}>
    <Card sx={{ maxWidth: '90%' }} className={classes.card}>
      <CardContent className={classes.card_content}>
          <BasicSelect id='empleado' title='Nombre y apellidos' 
              value={userState.employee ? userState.employee + '@' + userState.name : ' '} 
              labelNone='Seleccione Empleado'
              options={employeesList}
              disabled={!isManager} width={300} onChange={onChangeEmployee} />  
          <br/>
          <BasicSelect id='anio' title='A&ntilde;o' value={userState.year} options={anios} nullOption={false}
              onChange={onChangeYearHandler} />  
          <BasicTextField id='gerente' title='Gerente' value={part.manager} width='40ch'/>
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={12}sm={3} md={3}>
    <Card sx={{ minWidth: 200 }} className={`${classes.thin} ${classes.card}`}>
      <CardContent className={classes.card_content}>
        <BasicTextField id='totales' title='Dias totales' value={part.totalDays} />
        <BasicTextField id='pendientes' title='Dias pendientes' value={part.pendingDays} />
        <BasicTextField id='enjoyeds_' title={`Dias disfrutados de ${part.prevYear}`}
            value={part.enjoyedPrevYear} />
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={12}sm={3} md={3}>
   <Card sx={{ minWidth: 200 }} className={`${classes.thin} ${classes.card}`}>
      <CardContent className={classes.card_content}>
        <BasicTextField id='trámite' title='Dias en trámite' value={part.inProcessDays} />
        <BasicTextField id='enjoyedsTotal' title='Dias disfrutados' value={part.enjoyed} />
        <BasicTextField id='enjoyeds' title={`Dias disfrutados de ${part.year}`}
            value={part.enjoyedYear} />
      </CardContent>
    </Card>
  </Grid>
  <Grid item xs={12}sm={12} md={3} lg={3} className={classes.contentLegend}> 
        <span className={`${classes.legend} ${classes.margin_right} ${classes.draft}`}>Borrador</span>
        <span className={`${classes.legend} ${classes.margin_right} ${classes.sent}`}>Enviado</span>
        <span className={`${classes.legend} ${classes.margin_right} ${classes.visado}`}>Visado</span>
        <span className={`${classes.legend} ${classes.margin_right} ${classes.refused}`}>Rechazado</span>
        <span className={`${classes.legend} ${classes.enjoyed}`}>Disfrutado</span>
    </Grid>
</Grid>

{userState.employee && userState.year && <div className='container_'>
    <HolidayList part={part}/>
    <div className={cx({
                'InfoMessage': true,
                'msg': error || error1
              })} >
      {error || error1}
    </div>

  <Toolbar 
        modifiable={userState.year === new Date().getFullYear()}
        onSave={onSaveHandler}
        onSend={onSendHandler}
        onCancel={onCancelHandler}
        holidays={true}
    ></Toolbar>
</div>}

</Fragment>
  );
};

export default Holidays;
