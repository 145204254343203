import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import classes from './Auth.module.css';
import { auth, checkEnterprise } from '../../store/actions';
import { RootState } from '../..';
import LoadingSpinner from '../../components/UI/LoadingSpinner';
import { Button } from '@mui/material';


const Auth: React.FC = () => {
  const dispatch = useDispatch();
  const { loading, error, enterprises } = useSelector((state: RootState) => state.auth);
  const [enterprise, setEnterprise] = useState('');

  const[userInput, setUserInput] = useState("");
  const[passwordInput, settPasswordInput] = useState("");
  
  const navigate = useNavigate();

  const loginHandler = (event: React.FormEvent) => {
    event.preventDefault();

    dispatch(auth(userInput, passwordInput, navigate));
  };

  const handleEnterpriseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setEnterprise(event.target.value);
    dispatch(checkEnterprise(userInput, passwordInput, Number(event.target.value), navigate));
  };

  if (loading) {
    return (
      <div className='centered'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <main className={`${classes.auth} d-flex flex-column justify-content-between `}>
      <section >
        <h3 className={"text-danger"}>SIPAC</h3>
        <h5>Sistema interno de partes</h5>
      </section>

      <section >
        <div className="text-center">
          <div className={`${classes.login_icon} rounded-circle text-light bg-danger`} >
            < i className="bi bi-lock fa-3x"></i>
          </div>
        <h5>Login</h5>
        </div>
      </section>

      <section >
        <form onSubmit={loginHandler} className={classes.form}>
          <div className={classes.control}>
            <label htmlFor='user'>Usuario</label>
            <input type='text' id='user' value={userInput} 
                onChange={(e) => setUserInput(e.target.value) }
                disabled={enterprises.length > 1}/>
          </div>
          <div className={classes.control}>
            <label htmlFor='password'>Contrase&ntilde;a</label>
            <input type='password' id='password' value={passwordInput} 
                onChange={(e) => settPasswordInput(e.target.value) }/>
          </div>
          {enterprises.length >= 1 &&
            <div className={classes.control}>
            <label htmlFor='empresa'>Empresa</label>
            <select id='empresa' onChange={handleEnterpriseChange} value={enterprise}
                className={`${classes.clsSelec}`} title='Seleccionar empresa'>
                <option value=''></option>
                {enterprises.map((option:any, index:number) => {
                  return <option key={option.cdempresa} value={option.cdempresa}>
                      {option.dsempresa}
                  </option>
              })}
            </select>
            </div>
          }
          <div className={classes.actions}>
          {(!enterprises || enterprises.length < 2) &&
            <Button variant="contained" color="warning" type="submit">
               <span className='btn btn-labeled'>
                <i className="fa fa-key"></i></span>Login
            </Button>}
          </div>
          <h3 className={`${classes.error} InfoMessage`}>
            {error}
          </h3>
        </form>
      </section>
    </main>
  );
};

export default Auth;
