import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '..';
import classes from './Home.module.scss';
import { PwdDialog } from './PwdDialog';
import classNames from "classnames/bind";
import { useLocation } from 'react-router-dom';
import { warning } from '@remix-run/router';

const cx = classNames.bind(classes);

const Home: React.FC = () => {
    const location = useLocation();
    const {token, error} = useSelector((state: RootState) => state.auth);
    const [employee, setEmployee] = useState();
    const [open, setOpen] = useState(false);
    const [resetWarning, setResetWarning] = useState(false);
    const imgUrl = '/banner_home.png';

    useEffect(() => {
        // Default values
        let stUser = localStorage.getItem("user");
        let user = JSON.parse(stUser!);
        //
        setEmployee(user?.name);

        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('reset') === 'true') {
          setOpen(true);
          setResetWarning(true);
        }

    }, []);

    const onChangePassword = () => {
      setOpen(true);
    };

    const handleOk = () => {
      setOpen(false);
      setResetWarning(false);
    };

    const handleClose = () => {
      setOpen(false);
    };

  return (
    <Fragment>
<div className={classes.home} style = {{ backgroundImage: `url(${imgUrl})` ,
                backgroundSize: 'cover', 
                color: 'white',
                width: '100%',
                maxWidth: '100vw',
                paddingLeft: '2rem',
                paddingRight: '2rem',
                marginBottom: '1rem',
                minHeight: '200px'
                }}>
    <div>
    <h2>Bienvenid@ a SIPAC</h2>
    <span>Sistema Interno de Partes </span>
    <p>
        Lea atentamente las instrucciones para rellenar los partes
    </p>
    </div>
    {token &&<div className='d-flex flex-column justify-content-center'>
        <div>Empleado</div>
        <p>
            <b>{employee}</b>
            &nbsp;&nbsp;
            <button onClick={onChangePassword}  
            className='btn btn-labeled btn-danger'>
              <span className='btn-labeled'
                ><i className="fa fa-key"></i></span>Cambiar clave
        </button>
        </p>
    </div>}
</div>
    
    <div className={classes.activities}>
        <b>Parte de actividades</b>
        <ul className={classes.bloque}>
            <li>Seleccione el <b>Proyecto</b> y <b>Concepto</b> al que desee imputar horas. (Pulsando sobre el icono 'P' podrá seleccionarlos por nombre) </li>
            <li>Rellene para cada dia el <b>número de horas</b> para el Proyecto y Concepto seleccionado en la casilla correspondiente </li>
            <li>Si necesita añadir <b>observaciones</b> para algún día haga doble click sobre la casilla del día </li>
            <li>Para <b>crear un nuevo Proyecto o Concepto</b>, pulse el botón Añadir y repita los pasos anteriores </li>
            <li>Para <b>borrar un Proyecto y Concepto</b>, pulse el botón de Borrado (X) de la linea correspondiente </li>
            <li>Las imputaciones se podrán guardar en <b>modo 'borrador'</b> pulsando el botón Guardar </li>
            <li>Cuando termine y verifique el parte mensual, pulse el botón <b>Enviar</b> </li>
            <li>Las <b>Ausencias y Vacaciones</b> se imputarán con el proyecto correspondiente y con el número de horas habituales por día </li>
            <li>Cualquier <b>falta</b> deberá ser debidamente justificada (parte médico, justificante de examen, ...)</li>
        </ul>
    </div>
    <div className={classes.expenses}>
        <b>Parte de gastos</b>
        <ul className={classes.bloque}>
            <li>Seleccione el <b>Proyecto</b> y <b>Concepto</b> al que desee imputar gastos </li>
            <li>Seleccione el <b>rango de fechas</b> de imputación del gasto (no rellene la Fecha Hasta si es un gasto puntual) </li>
            <li>Seleccione el <b>Tipo de Gasto, Unidades, Importe</b> (opcional) y <b>Total</b>, y si corresponde, la <b>Descripción</b> </li>
            <li>Para crear un <b>nuevo Gasto</b>, pulse el botón Añadir y repita los pasos anteriores </li>
            <li>Para <b>borrar un Gasto</b>, pulse el botón de Borrado (X) de la linea correspondiente </li>
            <li>Los gastos se podrán guardar en <b>modo 'borrador'</b> pulsando el botón Guardar </li>
            <li>Cuando termine y verifique el parte mensual, pulse el botón <b>Enviar</b> </li>
            <li>Cualquier Gasto deberá ser debidamente <b>justificado</b>, mediante factura o documento similar </li>
        </ul>
        <p>&nbsp; </p>
    </div>
    <div className={classes.holidays}>
    <b>Solicitud de vacaciones</b> 
    <ul className={classes.bloque}>
        <li>Entrar en <b>“solicitud vacaciones”</b> y marcar los días deseados haciendo click sobre los recuadros. </li>
        <li>La solicitud se podrá guardar en modo <b>'borrador'</b> pulsando el botón <b>Guardar</b>. </li>
        <li>Para proceder al envío de la solicitud pulse el botón <b>Enviar</b>. </li>
        <li>No se podrá disfrutar de vacaciones que no estén debidamente solicitadas y <b>visadas por el gerente</b>. </li>
        <li>Una vez se haya visado su solicitud, en ‘Parte de Actividad’ <b>añadimos el proyecto “vacaciones”</b>, y éstas se cargarán automáticamente. </li>
    </ul>
    </div>
    <div className={cx({
                'InfoMessage': true,
                'msg': error
              })} >
          {error}
    </div>
    <PwdDialog open={open} resetWarning={resetWarning}
      onOkDialog={handleOk} onCloseDialog={handleClose}></PwdDialog>
    </Fragment>
  );
};

export default Home;
