import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { OptionType } from '../../types/Props';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';


const BasicSelect: React.FC<{title?: string; id?: string; value?: string; 
      options: OptionType[], disabled?: Boolean; width?:number; labelNone?:string, nullOption?:boolean,
      onChange?: (event: SelectChangeEvent) => void,
      onBlur?: (event: any) => void,
      className?: string,
      nullValue?: boolean,
      children?: React.ReactNode}> = (props) => {
  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: props.width || 120 }}>
    <InputLabel id={`lbl${props.id}`}>{props.title}</InputLabel>
    <Select
      labelId={`lbl${props.id}`}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      onBlur={props.onBlur}
      className={props.className}
      label={props.title}
    >
      {!props.disabled && (props.nullOption !== undefined?props.nullOption:true) && 
      <MenuItem value={props.nullValue ? ''  : " "}>
        <em> { props.labelNone? props.labelNone : 'None' }</em>
      </MenuItem>}
      {props.options.map((option: any, index) => (
            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
          ))}
    </Select>
  </FormControl>
  );
}

export default BasicSelect;