import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../..';
import { putActivity } from '../../store/actions/activities';

import classes from './DayDialog.module.scss';
import classNames from "classnames/bind";
import { rpad } from '../../lib/Utils';
const cx = classNames.bind(classes);

export const DayDialog: React.FC<{open: boolean;
  activIndex: number; day:number;
  onOkDialog: () => void;
  onCloseDialog: () => void;
  children?: React.ReactNode}> = (props) => {

  const { part} = useSelector((state: RootState) => state.activ);

  const hoursTextRef = useRef<HTMLInputElement>(null);
  const commentTextRef = useRef<HTMLTextAreaElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        hoursTextRef.current?.focus();
        hoursTextRef.current?.select();
      }, 100);
    }
  }, [props.open]);

  const handleOk = () => {
    part.activities[props.activIndex].hours[props.day] = Number(hoursTextRef.current?.value);
    part.activities[props.activIndex].obs[props.day] = commentTextRef.current?.value;
    dispatch(putActivity(props.activIndex, part.activities[props.activIndex]));
    props.onOkDialog();
  };

  let isGreaterThanZero = hoursTextRef && hoursTextRef?.current && Number(hoursTextRef?.current?.value) > 0;
  let stDate = 'Día ' + rpad(props.day + 1) + '/' + rpad(part.month + 1)  + '/' + rpad(part.year);

  return (
    <div>
      <Dialog open={props.open} onClose={props.onCloseDialog}>
        <DialogTitle>Actividades</DialogTitle>
        <DialogContent>
          <DialogContentText>
          {stDate}
          </DialogContentText>
          <input type="number" step="1" size={2} maxLength={2} min={0} max={24} placeholder="0" 
              ref={hoursTextRef}
              defaultValue={part.activities[props.activIndex]?.hours[props.day]}
              className={cx({
                'mat-input': true,
                'blue': isGreaterThanZero,
                'black': !isGreaterThanZero
              })}/>
          <br/><br/>
          <textarea id='comment' title="Email Address" rows={5} cols={30}
              defaultValue={part.activities[props.activIndex]?.obs[props.day]}
              ref={commentTextRef} className='w-100'>
          </textarea>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCloseDialog}>Cancelar</Button>
          <Button onClick={handleOk}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
