import { Fragment, useEffect, useState, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchActivities, putActivities, addActivity } from '../../store/actions';
import SelectMonth  from '../../components/select-month/SelectMonth';
import Toolbar from '../../components/toolbar/Toolbar';

import ActivitiesList from '../../components/activ/ActivitiesList';
import LoadingSpinner from '../../components/UI/LoadingSpinner';


import useHttp from '../../hooks/use-http';
import { getProjects, getConcepts, yearHolidays } from '../../lib/api';

import { RootState } from '../..';

import classNames from "classnames/bind";
import classes from './Activities.module.scss';
import { employeeReducer, initEmployee } from '../../lib/Utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Activity } from '../../types/Activity';
import { OptionType } from '../../types/Props';
import { Checkbox, FormControlLabel } from '@mui/material';

const cx = classNames.bind(classes);

class  ObjectKey {
};

const Activities: React.FC = () => {

  const location = useLocation();
  const navigate = useNavigate();
  
  const dispatch = useDispatch();
  const { part, loading, error, projectCods } = useSelector((state: RootState) => state.activ);
  const {rol, monthNotClosed } = useSelector((state: RootState) => state.auth);

  const [userState, dispatchEmployee] = useReducer(employeeReducer, initEmployee);

  const { sendRequest, status, data: projects, error: error1 } = useHttp(
    getProjects, true
  );
  const { sendRequest: sendRequest2, status: status2, data: concepts, error: error2 } = useHttp(
    getConcepts, false
  );
  const { sendRequest: sendRequest3, status: status3, data: holidaysRaw, error: error3 } = useHttp(
    yearHolidays, false
  );

  const [conceptsMap, setConceptsMap] = useState(new ObjectKey());
  const [holidays, setHolidays] = useState([]);

  const [forceSend, setForceSend] = useState(Boolean);


  useEffect(() => {
    // valores por defecto
    let d = new Date();
    let stUser = localStorage.getItem("user");
    let user = JSON.parse(stUser!);
    //
    const queryParams = new URLSearchParams(location.search);
    const employee = queryParams.get('employee') || user.employee;
    const name = queryParams.get('name') || user.name;
    const month = queryParams.get('month') === '0' ? 0 
                : Number(queryParams.get('month') || d.getMonth());
    const year = Number(queryParams.get('year')) || d.getFullYear();
    dispatchEmployee({ type: 'USER_CHANGE', employee, name, year, month });

    // Holidays
    sendRequest3({employee, year});
    // Projects
    sendRequest({employee, year, month: month + 1});
    // Activities
    dispatch(fetchActivities('token!!!', employee, year, month + 1));
  }, [dispatch, location.search, sendRequest]);

  useEffect(() => {
    let d = new Date();
    let stUser = localStorage.getItem("user");
    let user = JSON.parse(stUser!);
    //
    const queryParams = new URLSearchParams(location.search);
    const employee = queryParams.get('employee') || user.employee;
    const month = queryParams.get('month') === '0' ? 0 
              : Number(queryParams.get('month') || d.getMonth());
    const year = Number(queryParams.get('year')) || d.getFullYear();

    // Concepts, per project
    projectCods.forEach((cod: any) => {
      if (!conceptsMap || !conceptsMap[cod as keyof ObjectKey]) {
        sendRequest2({
          employee: userState.employee ? userState.employee : employee, 
          year: userState.year ? userState.year : year, 
          month: userState.month ? userState.month + 1 : month + 1, 
          project: cod});
      }
    });
  }, [projectCods, location.search, sendRequest2]);

  useEffect(() => {
    // Concepts, per project, return
    if (concepts) {
        setConceptsMap({
          ...conceptsMap,
          ...concepts})
    } 
  }, [concepts]);

  useEffect(() => {
    // holidays
    if (holidaysRaw) {
      const intArray = holidaysRaw
        .filter((item: any) => item.ano === userState.year && item.mes === userState.month + 1)
        .map((item: any) => item.dia);
      setHolidays(intArray);
    } 
  }, [holidaysRaw, userState.month]);
  
  const onChangeMonthHandler = (monthYear:any) => {
    let aux = monthYear.split("-");
    let month = parseInt(aux[0]);
    let year = parseInt(aux[1]);
    dispatchEmployee({ type: 'USER_CHANGE', employee: userState.employee, name: userState.name, year, month });
    dispatch(fetchActivities('token!!!', userState.employee, year, month + 1));
    // Refresh projects and concepts
    sendRequest({employee: userState.employee, year, month: month + 1});
    //
    setConceptsMap(new ObjectKey());
  }

  const onAddHandler = () => {
    dispatch(addActivity(part.year, part.month));
  };

  const onSaveHandler = () => {
    dispatch(putActivities('token!!!', part, 'GE', conceptsMap));
  };

  const onSendHandler = () => {
    dispatch(putActivities('token!!!', part, 'EE', conceptsMap, forceSend));
  };

  const onCancelHandler = () => {
    dispatch(fetchActivities('token!!!', part.employee, part.year, part.month + 1));
  };

  const handleForceSendChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForceSend(event.target.value === "on");
  };

  const onPrintHandler = () => {
    // html
    const tablePrint = document.querySelector('table.responsive');
    localStorage.setItem('print', tablePrint?.innerHTML ??'');
    // data
    let data: any[] = [];
    part.activities.forEach((item: Activity) => {
      const project = projects.find((proj:OptionType) => parseInt(proj.value) === item.project);
      const conceptsArr: any = conceptsMap[item.project as keyof ObjectKey];
      const concept = conceptsArr?.find((conc:OptionType) => parseInt(conc.value) === item.concept);
      data.push(project?.label??'');
      data.push(concept?.label??'');
    });
    localStorage.setItem('printSelec', JSON.stringify(data));
    // employee
    localStorage.setItem('employee', JSON.stringify(userState));
    navigate(`/print`);
  };

  if (status === 'pending' || status2 === 'pending' || loading) {
    return (
      <div className='centered'>
        <LoadingSpinner />
      </div>
    );
  }

  return (
  <Fragment>

  <div className='mt-4'/>

  <SelectMonth title='Parte de Actividades Mensual' 
    employee={part.employee} name={userState.name}
    monthYear={part.month + '-' + part.year}
    rol={rol}
    monthNotClosed={monthNotClosed}
    onChangeMonth={onChangeMonthHandler}
  ></SelectMonth>

<div className='container_'>
    <ActivitiesList part={part} 
      projects={projects ? projects : []} 
      concepts={conceptsMap}
      holidays={holidays} />

    {(error || error1 || error2) && <div className={cx({
                'InfoMessage': true,
                'msg-error': true,
              })} >
      {error || error1 || error2}
    </div>}

  <Toolbar 
        modifiable={part.modifiable}
        onAdd={onAddHandler}
        onSave={onSaveHandler}
        onSend={onSendHandler}
        onCancel={onCancelHandler}
        onPrint={onPrintHandler}
    ></Toolbar>

  {error &&
    <div className={cx({
      'text-end': true,
      'chk-force': true,
    })} >
    <FormControlLabel  control={<Checkbox checked={forceSend} onChange={handleForceSendChange}/>} 
      label="Enviar mi parte de actividad ignorando incongruencias con mis fichajes" />
    </div>
    }

</div>

</Fragment>
  );
};

export default Activities;
