
import { Fragment } from 'react';
import classes from './Toolbar.module.scss';

import classNames from "classnames/bind";
import { Button } from '@mui/material';

const cx = classNames.bind(classes);

const Toolbar: React.FC<{ 
  modifiable: boolean;
  onAdd?: () => void;
  onSave: () => void;
  onSend: () => void;
  onCancel: () => void;
  onPrint?: () => void;
  holidays?: boolean;
  children?: React.ReactNode }> = (props) => {


  return (
<Fragment>
  <div className={`${classes.row_justify}`}>
    <div className={`${classes.btn_izquierda}`}>
    {!props.holidays && <Button variant="contained" color="primary" onClick={props.onAdd}  
            disabled = {!props.modifiable}
            className={cx({
              'blur': !props.modifiable,
              'no-edit-allowed': !props.modifiable,
              'margin_right': true,
              'btn btn-purple': true
            })}>
             <span className={cx({
                  'btn-labeled': true,
                  'margin_right_small': true
                })}><i className="fa fa-plus"></i></span>A&ntilde;adir
        </Button>
        }
        <Button variant="contained" color="warning" onClick={props.onSave}  
            disabled = {!props.modifiable}
            className={cx({
              'blur': !props.modifiable,
              'no-edit-allowed': !props.modifiable,
              'margin_right': true
            })}>
               <span className={cx({
                  'btn-labeled': true,
                  'margin_right_small': true
                })}><i className="fa fa-save"></i></span>Guardar
        </Button>
    </div>

    <div className={`${classes.btn_derecha}`}>
        <Button variant="contained" color="success" onClick={props.onSend}  
            disabled = {!props.modifiable}
            className={cx({
              'blur': !props.modifiable,
              'no-edit-allowed': !props.modifiable,
              'margin_right': true
            })}>
              <span className={cx({
                  'btn-labeled': true,
                  'margin_right_small': true
                })}><i className="fa fa-save"></i></span>Enviar
        </Button>
        <Button variant="contained" color="error" onClick={props.onCancel}  
            className={cx({
              'margin_right': true
            })}>
              <span className={cx({
                  'btn-labeled': true,
                  'margin_right_small': true
                })}><i className="fa fa-remove"></i></span>Cancelar
        </Button>
        {!props.holidays && <Button variant="contained" color="secondary" onClick={props.onPrint}  
            className={cx({
              'text-white': true
            })}>
              <span className={cx({
                  'btn-labeled': true,
                  'margin_right_small': true
                })}><i className="fa fa-remove"></i></span>Imprimir
        </Button> }     
    </div>
  </div>
</Fragment>
  )
};

export default Toolbar;
