import { ImputePart } from "../types/Activity";
import { ExpensesPart } from "../types/Expense";
import { HolidaysPart } from "../types/Holiday";
import { OptionType } from "../types/Props";

export const initEmployee = { employee: '', name: '', month: null, year: null }

export const employeeReducer = (state: any, action: any) => {
  if (action.type === 'USER_CHANGE') {
    return { employee: action.employee, name: action.name, year: action.year, month: action.month };
  }
  if (action.type === 'USER_INIT') {
    return initEmployee;
  }
  return initEmployee;
};

export function inicializeMonths(rol: number, monthNotClosed: number){
    let lastMoonth = new Date();
    lastMoonth.setMonth(lastMoonth.getMonth() - 1);
    let startDate = rol === 4 ? new Date(2011, 0, 1) : lastMoonth;
    if (rol !== 4 && monthNotClosed)
    {
      const yyyy = Math.trunc(monthNotClosed / 100);
      const mm = monthNotClosed - yyyy * 100;

      startDate = new Date(yyyy, mm - 2, 1);
    }

    let endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1);

    let months: OptionType[] = [];

    let i = startDate;
    while (i.getFullYear() < endDate.getFullYear() || ( i.getFullYear() == endDate.getFullYear() && i.getMonth() <= endDate.getMonth()) )
    {
      const month = i.toLocaleString('default', { month: 'long' });
      months.push({value:i.getMonth() + '-' + i.getFullYear(), label: month + ' - ' + i.getFullYear()});
      
      i.setMonth(i.getMonth() + 1);
    }

    return months;
  }
  
  export const imputationsTotals = (part: ImputePart) => {
    part.total = 0;
    part.totals = getMonth(part.month, part.year);
  
    for (let actividad of part.activities) {
      actividad.total = 0;
      for(var i = 0; i < actividad.hours.length; i++){
        part.total += actividad.hours[i];
        part.totals[i] += actividad.hours[i];
        actividad.total += actividad.hours[i];
       }
    }
  }

  export const expensesTotals = (part: ExpensesPart) => {
    part.total = 0;

    for (let gasto of part.expenses) {
      gasto.nmTotal = gasto.nmAmount * gasto.nmUnits;
      gasto.nmTotal = round2decinals(gasto.nmTotal);
      part.total += gasto.nmTotal;
    }
    part.total = round2decinals(part.total);
  }

  
  export const holidaysTotal = (part: HolidaysPart) => {

    for (let vacac of part.holidays) {
      vacac.total = 0;
      for (let dia of vacac.days) {
        vacac.total += dia.check && dia.state !== 'OK' ? 1 : 0;
      }
    }
  } 

  export const round2decinals = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  export const getMonth = (month: number, year: number) => {
    var first = new Date(year, month, 1);
    var last = new Date(year, month + 1, 0);

    let horas = [];    

    for(var i=first.getDate(); i<=last.getDate(); i++){
      horas.push(0);
    }

    return horas;
  }

  
  export const stateDesc = (strCode: string): string =>
  {
      let strRet: string;
      switch (strCode)
      {
          case "GE":
              strRet = "Borrador";
              break;
          case "EE":
              strRet = "Enviado";
              break;
          case "VA":
          case "VG":
          case "EF":
              strRet = "Visado";
              break;
          case "RA":
          case "RG":
              strRet = "Rechazado";
              break;
          default:
              strRet = "";
              break;
      }
      return strRet;
  }

export const getError = (error: any) => {
  return error.response && error.response.data ? error.response.data : error.message
}

export const rpad = (str: any):string => str ? str.toString().padStart(2, '0') : ''

export const initalState = (part: HolidaysPart) => {
    let stateIni = '';
    part.holidays.forEach(
      (holiday, month) => {
          holiday.days.forEach(
              (dia, day) => {
                  if (holiday.disabled || !dia.check || (dia.state !== 'GE' && dia.state !== 'RG')) {
                  } else if (month === 12) {
                      stateIni += rpad(day + 1) + '/01/' + rpad(part.year + 1) + ','
                  } else {
                      stateIni += rpad(day + 1) + '/' + rpad(month + 1)  + '/' + rpad(part.year) + ',' 
                  }
              })
      })
    return stateIni;
}

export const generateArrayOfYears = () => {
  let max = new Date().getFullYear()
  let min = max - 9
  let years:any = []

  for (var i = max; i >= min; i--) {
    years.push(new OptionType('' + i, '' + i))
  }
  return years
}