import { delay, select } from "redux-saga/effects";
import { put, call } from "redux-saga/effects";
import axios from "../axios-sipac";
import { MOCK, LOGIN_MOCK } from "../../lib/mock";

import * as actions from "../actions/index";
import { getError } from '../../lib/Utils';
import { AxiosResponse } from "axios";

export function* logoutSaga(action: any) {
  yield call([localStorage, "removeItem"], "token");
  yield call([localStorage, "removeItem"], "auth");
  yield call([localStorage, "removeItem"], "user");
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action: any) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

function* getEnterprise(enterpriseToFind: number | null): any {
  const enterprises: any[] = yield select( (state) => state.auth.enterprises);
  var enterprise = enterprises?.find(item => {
    return item['cdempresa'] === enterpriseToFind
  })
  return enterprise ? enterprise['dsempresa'] : null;
}

export function* authUserSaga(action: any) {
  yield put(actions.authStart());
  let url = 'employee/login';
  try {
    let data = LOGIN_MOCK;
    if (!MOCK) {
      const encryptedAuth = window.btoa(action.user + '//:' + action.password);
      const response: AxiosResponse<any> = yield axios.post(
        url,
        {},
        { headers: { 'Authentication': encryptedAuth } }
      );
      data = response.data;
    }
/*    
    const expirationDate = yield new Date(
      new Date().getTime() + response.data.expiresIn * 1000
    );
*/
    if (!data || (!data['cdrol'] && !data['empresas'])) {
      throw new Error('Credenciales erróneas');
    }

    const enterprises: any = data['empresas'];
    let token: string | null = 'token!!!';
    let enterprise: number | null = null;
    let dsEnterprise: string | null = null;

    // Employee in multiple enterprises
    url = 'employee/enterprise/' + action.user;
    const response2: AxiosResponse<any> = yield axios.get(url);
    let data2 = response2.data;
    if (data2['length'] >= 1) {
      yield put(
        actions.setEnterprises(data2)
      );
    }
    if (enterprises > 1) {
      token = null;
    } else {
      enterprise = data2[0]['cdempresa'];
      dsEnterprise = data2[0]['dsempresa'];
    }

    let login = {
      usuario: action.user, password: action.password
    };
    let user = {
      employee: data['empleado'], name: data['nombrecompleto'], rol: data['cdrol'], 
        manager: data['cdgerente'], unit: data['cdunidad'], enterprises
    };
    yield localStorage.setItem("auth", JSON.stringify(login));
    yield localStorage.setItem("user", JSON.stringify(user));
    yield localStorage.setItem("token", data['token']);
    //yield localStorage.setItem("expirationDate", expirationDate);

    const monthNotClosed: number = yield notClosedMonth() 
    yield put(
      actions.authSuccess(token, action.user, user.rol, enterprise, dsEnterprise, monthNotClosed)
    );
    //yield put(actions.checkAuthTimeout(response.data.expiresIn));
    if (!enterprises || enterprises <= 1) {
      action.navigate('/home?reset=' + (action.password === '1234567890'))
    }
  } catch (error) {
    yield put(actions.authFail(getError(error)));
  }
}

export function* checkEnterpriseSaga(action: any) {
  yield put(actions.authStart());
  let url = 'employee/check/' + action.user + "/" + action.enterprise;
  try {
    const response: AxiosResponse<any> = yield axios.get(url);
    let data = response.data;

    if (!data || (!data['cdrol'] && !data['empresas'])) {
      throw new Error('Credenciales erróneas');
    }

    let login = {
      usuario: action.user, password: action.password
    };
    let user = {
      employee: data['empleado'], name: data['nombrecompleto'], rol: data['cdrol'], 
        manager: data['cdgerente'], unit: data['cdunidad'], 
        enterprises: 1, enterprise: action.enterprise
    };
    yield localStorage.setItem("auth", JSON.stringify(login));
    yield localStorage.setItem("user", JSON.stringify(user));
    yield localStorage.setItem("token", data['token']);
    yield put(
      actions.authSuccess('token!!!', action.user, user.rol, action.enterprise, getEnterprise(action.enterprise))
    );
    action.navigate('/home?reset=' + (action.password === '1234567890'))
  } catch (error) {
    yield put(actions.authFail(getError(error)));
  }
}

export function* changePasswordSaga(action: any) {
  yield put(actions.authStart());
  let url = 'employee/password';
  try {
      let body: any = {};
      body.user = action.user;
      body.password = action.password;
      const response: AxiosResponse<any> = yield axios.post(url, body);
      let ret = response.data;

    if (!ret || !ret['return']) {
      throw new Error('La clave no ha sido cambiada con éxito.');
    }

    yield put(
      actions.authSuccess('token!!!', action.user, action.rol, action.enterprise, getEnterprise(action.enterprise))
    );
  } catch (error) {
    yield put(actions.authFail(getError(error)));
  }
}

export function* authCheckStateSaga(action: any) {
  const token: string = yield localStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate: Date = yield new Date(
      localStorage.getItem("expirationDate") || 0
    );
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const stAuth: string = yield localStorage.getItem("auth");
      const stUser: string = yield localStorage.getItem("user");
      const auth = JSON.parse(stAuth);
      const user = JSON.parse(stUser);
      yield put(actions.authSuccess(token, auth.usuario, user.rol, user.enterprise, getEnterprise(action.enterprise)));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}

export function* notClosedMonth() {

  const response: AxiosResponse<any> = yield axios.get('not-closed-month');

  return response.data['return'];
}