import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';

import classes from './PwdDialog.module.scss';
import classNames from "classnames/bind";
import { authChangePassword } from '../store/actions/auth';
import { TextField } from '@mui/material';

const cx = classNames.bind(classes);


export const PwdDialog: React.FC<{open: boolean; resetWarning: boolean;
  onOkDialog: () => void;
  onCloseDialog: () => void;
  children?: React.ReactNode}> = (props) => {
  const [error, setError] = useState('');
  const { userId, rol, enterprise} = useSelector((state: RootState) => state.auth);

  const pwdTextRef = useRef<HTMLInputElement>(null);
  const confirmTextRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.open) {
      setError('');
    }
  }, [props.open]);


  const handleOk = (event: React.FormEvent) => {
    event.preventDefault();

    const enteredPassword = pwdTextRef.current?.value;
    const confirmPassword = confirmTextRef.current?.value;
    // TODO: validate confirm pwd
    if (!enteredPassword || !confirmPassword) {
      setError('Campos obligatorios');
    } else if (enteredPassword !== confirmPassword) {
      setError('La clave que ha introducido no coincide con la confirmación');
    } else if (enteredPassword === '1234567890') {
      setError('Clave no válida');
    } else {
      setError('');
      dispatch(authChangePassword(userId, rol, enteredPassword, enterprise));
      props.onOkDialog();
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={props.onCloseDialog} 
            maxWidth={'sm'} fullWidth>
        <DialogTitle><span className={classes.lblRed}>Cambio de clave</span></DialogTitle>
        <DialogContent>
          {props.resetWarning && <p className={classes.red}>
            <div>Está usando la contraseña asignada por defecto por el sistema.</div>
            <div>DEBE CAMBIARLA.</div>
          </p>}
          <TextField  
              autoFocus
              margin="dense"
              inputRef={pwdTextRef}
              label="Nueva clave"
              fullWidth
              variant="standard" 
              type="password"/>
          <br/><br/>
          <TextField 
              margin="dense"
              inputRef={confirmTextRef}
              label="Confirmar clave"
              fullWidth
              variant="standard"
              type="password" />
          <div className={cx({
                'InfoMessage': true,
                'msg': error
              })} >
          {error}
        </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCloseDialog}>Cancelar</Button>
          <Button onClick={handleOk}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
