
import { Fragment, useEffect, useReducer, useState } from 'react';
import { inicializeMonths } from '../../lib/Utils';
import Card from '../../components/UI/Card';
import classes from './Employees.module.scss';
import useHttp from '../../hooks/use-http';
import { getEmployees } from '../../lib/api';

import classNames from "classnames/bind";
import { useNavigate } from 'react-router-dom';
import { Button, SelectChangeEvent } from '@mui/material';
import BasicSelect from '../../components/UI/BasicSelect';
import { RootState } from '../..';
import { useSelector } from 'react-redux';
import { OptionType } from '../../types/Props';

const cx = classNames.bind(classes);

const initialInputState =  { value: '', isTouched: false };
const inputStateReducer = (state: any, action: any) => {
  if (action.type === 'INPUT') {
    return { value: action.value, isTouched: state.isTouched };
  }
  if (action.type === 'BLUR') {
    return { isTouched: true, value: state.value };
  }
  if (action.type === 'RESET') {
    return initialInputState;
  }
  return initialInputState;
};

const initMonths: OptionType[] = [];

const Employees: React.FC = (props) => {
  const {rol, enterprise, monthNotClosed } = useSelector((state: RootState) => state.auth);
  
  const [months, setMonths] = useState(initMonths);

  let navigate = useNavigate();
  

  const { sendRequest, data: employees, error } = useHttp(
    getEmployees, true
  );

  const [selecteEmployee, dispatch] = useReducer(inputStateReducer, initialInputState);
  //const [selecteEmployee, setSelectedEmployee] = useState('');
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + '-' + today.getFullYear());

  useEffect(() => {
    // valores por defecto
    let d = new Date();
    let stUser = localStorage.getItem("user");
    let user = JSON.parse(stUser!);

    // Employees
    sendRequest({manager: user.employee, year: d.getFullYear(), month: d.getMonth() + 1, rol, enterprise});

    setMonths(inicializeMonths(rol, monthNotClosed))

  }, [sendRequest]);


  const handleEmployeeChange = (event: SelectChangeEvent<string>) => {
    dispatch({ type: 'INPUT', value: event.target.value });
    //setSelectedEmployee(event.target.value);
  };
  const inputBlurHandler = (event: any) => {
    dispatch({ type: 'BLUR' });
  };

  const handleMonthChange = (event: SelectChangeEvent<string>) => {
    setSelectedMonth(event.target.value);
  };

  const onActivHandler = () => {
    dispatch({ type: 'BLUR' });
    if (selecteEmployee.value.trim()) {
      let aux = selectedMonth.split("-");
      let aux2 = selecteEmployee.value.split('@');
      let name = encodeURI(aux2[1]);
      navigate(`/activities?employee=${aux2[0]}&month=${aux[0]}&year=${aux[1]}&name=${name}`);
    }
  };
  
  const onExpensesHandler = () => {
    dispatch({ type: 'BLUR' });
    if (selecteEmployee.value.trim()) {
      let aux = selectedMonth.split("-");
      let aux2 = selecteEmployee.value.split('@');
      let name = encodeURI(aux2[1]);
      navigate(`/expenses?employee=${aux2[0]}&month=${aux[0]}&year=${aux[1]}&name=${name}`);
    } 
  };
  
  let employeesList: any[] = [];
  if (employees) {
    employeesList = employees;
  }

  const employeeInputInvalid = !selecteEmployee.value.trim() && selecteEmployee.isTouched;

  return (
    <Fragment>
    <Card clsCard={classes.card}>
      <h3>Seguimiento de los Empleados</h3>
      <figure className={cx({
            'form_control': true,
            'invalid': employeeInputInvalid
          })} >
        <div style={{display: 'flex', alignItems: 'center'}} >
        <BasicSelect onChange={handleEmployeeChange} 
          value={selecteEmployee.value ? selecteEmployee.value : ' '} 
          onBlur={inputBlurHandler}
          className={classes.clsSelec}
          labelNone='Seleccione Empleado'
          options={employeesList} />
        <img src="login_head.png" title='Empleado...' alt='Empleado...' 
              className={classes.employee_icon} />
        </div>
      {employeeInputInvalid && (
          <p className={classes.error_text}>Empleado requerido.</p>
          )}
      </figure>
      <div className={classes.form_control}>
        <BasicSelect onChange={handleMonthChange} value={selectedMonth}
          className={`${classes.clsSelec}`} labelNone='Select month' nullOption={false}
          options={months} />
      </div>
    </Card>
    <div className={cx({
                'InfoMessage': true,
                'InfoMessage2': true,
                'msg': error
              })} >
      {error}
    </div>

    <div className={`${classes.mi_container}`}>
    <Button variant="contained" color="success" onClick={onActivHandler}  
        className={cx({
          'margin_right': true
        })}>
          <span className={cx({
              'btn-labeled': true,
              'margin_right_small': true
            })}><i className="fa fa-list"></i></span>Consultar Actividades
    </Button>
    <Button variant="contained" color="error" onClick={onExpensesHandler} >
          <span className={cx({
              'btn-labeled': true,
              'margin_right_small': true
            })}><i className="fa fa-clipboard"></i></span>Consultar Gastos
    </Button>
    </div>
    </Fragment>
  );
};

export default Employees;
