import { put } from "redux-saga/effects";

import axios from "../axios-sipac";
import * as actions from "../actions";

import { getError, initalState } from '../../lib/Utils';
import { MOCK } from "../../lib/mock";
import { HolidaysPart } from "../../types/Holiday";
import { AxiosResponse } from "axios";

export function* putHolidaysSaga(action: any) {
  yield put(actions.putHolidaysStart());
  let stUsuario = localStorage.getItem("user");
  let usuario = JSON.parse(stUsuario!).employee;
  const url = `holidays_employee/${action.part.employee}/${action.part.year}/${action.state}/${usuario}`;
  try {
    // validate
    const msg: string = yield validate(action.state, action.part);
    if (msg) {
      throw new Error(msg);
    }

    yield axios.put(url, action.part);
    yield put(
      actions.putHolidaysSuccess(action.state === 'G' ? 'Guardado' : 'Enviado')
    );
    // Refresh changes
    if (action.state === 'E') {
      yield put(
        actions.fetchHolidays('token!!!', action.part.employee, action.part.year)
      );
    }
  } catch (error) {
    yield put(actions.putHolidaysFail(getError(error)));
  }
}

export function* fetchHolidaysSaga(action: any) {
  yield put(actions.fetchHolidaysStart(action.employee, action.year));
  if (MOCK) {
    yield put(actions.fetchHolidaysFail('Mocked'));
    return;
  }
  let url = `holidays_employee/${action.employee}/${action.year}`;

  try {
    const response: AxiosResponse<any> = yield axios.get(url);

    url = `disableHolidays/${action.employee}/${action.year}`;
    const response2: AxiosResponse<any> = yield axios.get(url);

    url = `festiveDays/${action.employee}/${action.year}`;
    const response3: AxiosResponse<any> = yield axios.get(url);

    yield put(actions.fetchHolidaysSuccess(action.year, response.data, response2.data, response3.data));
  } catch (error) {
    yield put(actions.fetchHolidaysFail(getError(error)));
  }
}


function validate(state: string, part: HolidaysPart) {

  let stateIni = initalState(part);
  if (   ( (state === "G") && (stateIni === "") ) ||
  ( (state === "G") && (stateIni !== part.stateIni) ) || 
  ( (state === "E") && (stateIni !== "") ) 
)
{
  return null;
}
  
  return 'No ha realizado ninguna modificación, por tanto no se enviara a su gerente';
}
