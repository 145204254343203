import { put } from "redux-saga/effects";

import axios from "../axios-sipac";
import * as actions from "../actions";

import { expensesTotals, getError } from '../../lib/Utils';
import { Expense, ExpensesPart } from "../../types/Expense";
import { MOCK, GASTOS_MOCK } from "../../lib/mock";
import { OptionType } from "../../types/Props";
import { AxiosResponse } from "axios";

export function* putExpensesSaga(action: any) {
  yield put(actions.putExpensesStart());
  let mes = action.part.month + 1;
  let stLogin = localStorage.getItem("auth");
  let usuario = JSON.parse(stLogin!).usuario;
  const url = `employee/expenses/${action.part.employee}/${mes}/${action.part.year}/${action.state}/${usuario}`;
  try {
    // validate
    const msg:string = yield validate(action.part);
    if (msg) {
      throw new Error(msg);
    }

    yield axios.put(url, action.part);
    yield put(
      actions.putExpensesSuccess(action.state, action.state === 'GE' ? 'Guardado' : 'Enviado')
    );
  } catch (error) {
    yield put(actions.putExpensesFail(getError(error)));
  }
}

export function* fetchExpensesSaga(action: any) {
  yield put(actions.fetchExpensesStart(action.employee, action.month - 1, action.year));
  if (MOCK) {
    yield put(actions.fetchExpensesSuccess(GASTOS_MOCK));
  }
  const url = `employee/expenses/${action.employee}/${action.month}/${action.year}`;

  try {
    const response: AxiosResponse<any> = yield axios.get(url);
    const fetchedExpenses = convertExpenses(response.data, 
      action.employee, action.month - 1, action.year, action.projects);

    yield put(actions.fetchExpensesSuccess(fetchedExpenses));
  } catch (error) {
    yield put(actions.fetchExpensesFail(getError(error)));
  }
}

const convertExpenses = (data: any, gemployee: number, month: number, year: number, projects: OptionType[]) => {

  let ret;
  ret = new ExpensesPart();
  ret.employee = gemployee;
  ret.month = month;
  ret.year = year;

  let expenses = [];
  for(var i=0; i<data.length; i++){

    // Empty expense
    let expense = new Expense();

    expense = new Expense();

    expense.project = getProjectCode(data[i]['codproy_axpe'], projects); // TODO es la descripcion, pasar a codigo
    expense.dateRange = [new Date(data[i]['fecha_gasto']), new Date(data[i]['fecha_hasta'])];
    expense.expenseType  = data[i]['cdtipo_gasto'];
    expense.nmUnits = data[i]['unidades'];
    expense.nmAmount = data[i]['importe_unidad'];
    expense.nmTotal = data[i]['importe_total'];
    expense.dsDescription = data[i]['observaciones'];
    expense.state  =  data[i]['estado'];

    expenses.push(expense); 
  }
  // Order by state and date
  ret.expenses = expenses.sort((a, b) => {
    if (a.state === b.state) {
      return a.dateRange[0]! > b.dateRange[0]! ? 1 : -1; 
    } else if (a.state === 'GE') {
      return 1;
    } else if (b.state === 'GE') {
      return -1;
    }
    return 0;
  });

  expensesTotals(ret);
  
  return ret;
}


function validate(part: ExpensesPart) {

  for (let i in part.expenses) {
      let expense = part.expenses[i];
      let indice = Number(i) + 1;
      if (!expense.project) {
          return  'No se puede dejar vacío el proyecto de la línea ' + indice
      } else if (expense.dateRange[0] === null ||
                isNaN(expense.dateRange[0].getTime()) ||
                expense.dateRange[1] === null ||
                isNaN(expense.dateRange[1].getTime())) {
        return  'No se puede dejar vacía la fecha de la línea ' + indice
      } else if (expense.dateRange[0].getTime() > expense.dateRange[1].getTime()) {
        return  'Rango de fechas incorrecto en la línea ' + indice
      } else if (!expense.expenseType) {
        return  'No se puede dejar vacía el tipo de la línea ' + indice
      } else if (!expense.nmUnits || expense.nmUnits === 0) {
        return  'No se puede dejar vacía unidades en la línea ' + indice
      } else if (expense.nmUnits.toString().length > 9) {
        return  'El campo unidades no puede tener más de 9 dígitos en la línea ' + indice
      } else if (!expense.nmAmount || expense.nmAmount === 0) {
        return  'No se puede dejar vacía importe unidad en la línea ' + indice
      } else if (expense.nmAmount.toString().length > 9) {
        return  'El campo importe unidad no puede tener más de 9 dígitos en la línea ' + indice
      } else if (!expense.nmTotal || expense.nmTotal === 0) {
        return  'No se puede dejar vacía importe total en la línea ' + indice
      } else if (expense.nmTotal.toString().length > 12) {
        return  'El campo importe total no puede tener más de 12 dígitos enteros en la línea ' + indice
      }
  }
  
  return null;
}


function getProjectCode(proj: string, projects: OptionType[]): number {
  for (let x of projects) {
    if (x.label.includes(proj)) {
        return Number(x.value);
      }
  }
  
  return 0;
}

