import { useDispatch } from 'react-redux';

import classes from './ExpenseItem.module.scss';

import classNames from "classnames/bind";
import { useState } from 'react';
import { Expense } from '../../types/Expense';
import { deleteExpense, putExpense } from '../../store/actions/expenses';
import { stateDesc, round2decinals } from '../../lib/Utils';
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; 

registerLocale("es", es);

const cx = classNames.bind(classes);


const ExpenseItem: React.FC<{index: number; expense: Expense; 
          projects: any[], concepts: any;
          children?: React.ReactNode}> = (props) => {
  const dispatch = useDispatch();

  var rowClass = cx({
    'expenses': true,
    'anchoAuto': true,
    'no-edit-allowed blur': props.expense.state === 'EE' || props.expense.state === 'VG' || props.expense.state === 'EF' || props.expense.state === 'VF'  || props.expense.state === 'VA'
  });
  var stateClass = cx({
    'draft': props.expense.state === 'GE', 
    'sent': props.expense.state === 'EE',
    'visado': props.expense.state === 'VG' || props.expense.state === 'VF' || props.expense.state === 'EF' || props.expense.state === 'VA',
    'refused': props.expense.state === 'RG' || props.expense.state === 'RF'
  });

  const [selectedProject, setSelectedProject] = useState(props.expense.project);
  const [selectedConcept, setSelectedConcept] = useState(props.expense.expenseType);

  const handleProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedProject(parseInt(event.target.value));
    props.expense.project = parseInt(event.target.value);
    dispatch(putExpense(props.index, props.expense));
  };

  const handleConceptChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedConcept(parseInt(event.target.value));
    props.expense.expenseType = parseInt(event.target.value);
    dispatch(putExpense(props.index, props.expense));
  };

  const deleteHandler = () =>{
    dispatch(deleteExpense(props.index));
  }

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>, field: string, isNumber: boolean = true) =>{
    let aux = {
      ...props.expense,
      [field]: isNumber ? Number(event.target.value) : event.target.value
    }
    dispatch(putExpense(props.index, aux));
  }

  const focusHandler = (event: React.FocusEvent<HTMLInputElement>) =>{
    // Select content on focus
    event.target.select();
  }

  const blurHandler = (event: React.ChangeEvent<HTMLInputElement>, field: string, isNumber: boolean = true) =>{
    let val = Number(event.target.value);
    let aux = {
      ...props.expense,
      [field]: round2decinals(val)
    }
    dispatch(putExpense(props.index, aux));
  }

  const dateRangeHandler = (date: any, index:number) => {
    console.log(date);
    props.expense.dateRange[index] = date;
    dispatch(putExpense(props.index, props.expense));
  }
        
  return (
    <tr className={rowClass}>
      <td className="mv-grid-title-td-hide">Proyecto</td>
      <td>
        <select onChange={handleProjectChange} value={selectedProject}
          className={classes.clsSelec} title='Select project'>
        <option key="-1" value=''></option>
        {props.projects.map((option, index) => {
            return <option key={option.value}  value={option.value}>
                {option.label}
            </option>
        })}
      </select>
      </td>

      <td className="mv-grid-title-td-hide">Desde - Hasta</td>
      <td className={`${classes.rango_fechas} text-center rango_fechas`}>
      <DatePicker wrapperClassName={classes.datePicker} dateFormat="dd/MM/yyyy" locale={es}
          selected={props.expense.dateRange[0]}
          onSelect={(e) => dateRangeHandler(e, 0)} //when day is clicked
          onChange={(e) => dateRangeHandler(e, 0)} //only when value has changed
        />
      </td>
      <td className="d-none"></td>
      <td className={`${classes.rango_fechas} text-center rango_fechas`}>
        <DatePicker wrapperClassName={classes.datePicker} dateFormat="dd/MM/yyyy" locale={es}
          selected={props.expense.dateRange[1]}
          onSelect={(e) => dateRangeHandler(e, 1)} //when day is clicked
          onChange={(e) => dateRangeHandler(e, 1)} //only when value has changed
        />
      </td>

      <td className="mv-grid-title-td-hide ">Tipo</td>
      <td>
        <select onChange={handleConceptChange} value={selectedConcept}
          className={classes.clsSelec} title='Select concept'>
          <option key="-1" value=''></option>
          {props.concepts.map((option:any, index:number) => {
            return <option key={option.value}  value={option.value}>
                {option.label}
            </option>
        })}
      </select>
      </td>

      <td className="mv-grid-title-td-hide ">Unidades</td>
      <td className='rounded' >
      <input type="number" placeholder="0" size={9} maxLength={9}
              className={`${classes.mat_input_element} ${classes.txt_corto} text-end`}
              value={props.expense.nmUnits} 
              onFocus={focusHandler}
              onChange={(e) => inputHandler(e, 'nmUnits')} 
              onBlur={(e) => blurHandler(e, 'nmUnits')}  />
      </td>
      <td className="mv-grid-title-td-hide ">Importe</td>
      <td className='rounded' >
      <input type="number" placeholder="0" size={9} maxLength={9}
              className={`${classes.mat_input_element} ${classes.txt_corto} text-end`}
              value={props.expense.nmAmount} 
              onFocus={focusHandler}
              onChange={(e) => inputHandler(e, 'nmAmount')}
              onBlur={(e) => blurHandler(e, 'nmAmount')} />
      </td>

      <td className="mv-grid-title-td-hide ">Total</td>
      <td className='rounded' >
      <input type="number" placeholder="0" size={9} maxLength={20}
              className={`${classes.mat_input_element} ${classes.txt_corto} text-end`}
              value={props.expense.nmTotal} readOnly={true} />
      </td>

      <td className="mv-grid-title-td-hide ">Descripci&oacute;n</td>
      <td className="rounded">
      <input title="Descripci&oacute;n" maxLength={100} size={40}
              className={`${classes.mat_input_element} text-start`}
              value={props.expense.dsDescription} onChange={(e) => inputHandler(e, 'dsDescription', false)} />
      </td>
      <td className="mv-grid-title-td-hide ">Estado</td>
      <td className={`${stateClass} text-center rounded`}>
      <input type="text" size={20} maxLength={100}
            className={`${classes.mat_input_element} ${classes.txt_corto}`}
            value= {stateDesc(props.expense.state!)} readOnly={true} 
						 aria-label="Estado"/>
      </td>
      <td className="mv-grid-title-td-hide "></td>   
      <td className={stateClass}
            onClick={deleteHandler} >
        <span>
        <i className="fa fa-remove fa-2x"></i>
        </span>
      </td>
    </tr>

  );
};

export default ExpenseItem;
