import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

import { holidaysTotal, initalState } from '../../lib/Utils';
import { MonthHolidays, Holiday, HolidaysPart } from '../../types/Holiday';
import { AnyAction } from 'redux';

// Define a type for the slice state
interface HolidaysState {
    part: HolidaysPart,
    error: string | null,
    loading: boolean
  }

const initialState: HolidaysState = {
    part: new HolidaysPart(),
    loading: false,
    error: null
};

const holidaysInit = ( state: HolidaysState, action: AnyAction  ) => {
    return updateObject( state, 
        { loading: false, 
            error: null, 
            part: initHolidays(action.employee, action.year) } );
};

const putHolidaysStart = ( state: HolidaysState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: true,
        error: null
    } );
};

const putHolidaysSuccess = ( state: HolidaysState, action: AnyAction  ) => {
    return updateObject( state, {
        loading: false,
        error: action.msg
    } );
};

const putHolidaysFail = ( state: HolidaysState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: false,
        error: action.error
    } );
};

const fetchHolidaysStart = ( state: HolidaysState, action: AnyAction  ) => {
    return updateObject( state, 
        { loading: true, 
            error: null, 
            part: initHolidays(action.employee, action.year) } );
};

const fetchHolidaysSuccess = ( state: HolidaysState, action: AnyAction  ) => {

    let curYear = new Date().getFullYear()  
    state.part.inProcessDays = 0;
    for (let vac of action.holidays) {
        if (vac.dia === '0') {
            //
            state.part.totalDays = vac.totals;
            state.part.pendingDays = vac.pending;
            state.part.enjoyedPrevYear = vac.enjoyedPrevYear;
            state.part.enjoyed = vac.enjoyed;
            state.part.enjoyedYear = vac.enjoyedYear;
            state.part.manager = vac.est;
            continue;
        }
        let dateObj = new Date(vac.dia);
        if (dateObj.getUTCFullYear() !== action.year
                && !(dateObj.getUTCFullYear() === (action.year + 1) && action.year === curYear)) {
            continue;
        }
        let month = dateObj.getUTCMonth();
        let day = dateObj.getUTCDate() - 1;
        
        if (action.year === curYear 
            && dateObj.getUTCFullYear() === curYear + 1)
        {
            month = 12;
        }
        state.part.holidays[month].days[day].check = true;
        state.part.holidays[month].days[day].state = vac.est;
        state.part.holidays[month].days[day].visado = vac.vis;

        if (vac.est !== 'OK') {
            state.part.inProcessDays++;
        }
      } 
      for (let aux of action.disable) {
        state.part.holidays[aux.numMes - 1].disabled = true
      }

      // Initial state, to check changes
      state.part.stateIni = initalState(state.part);

      for (let rec of action.festiveDays) {
        let day = rec['dia'];
        let month = rec['mes'];
        state.part.holidays[month - 1].days[day - 1].festive = true
      }
      
    holidaysTotal(state.part);
    return updateObject( state, {
        part: state.part,
        loading: false,
        error: null
    } );
};

const fetchHolidaysFail = ( state: HolidaysState, action: AnyAction  ) => {
    return updateObject( state, { 
        loading: false,
        error: action.error
    } );
};

const checkHoliday = ( state: HolidaysState, action: AnyAction  ) => {
    state.part.holidays[action.indexMonth].days[action.indexDay].check = action.check;
    state.part.holidays[action.indexMonth].days[action.indexDay].state = action.check ? 'GE' : null;
    if (action.check) {
        state.part.inProcessDays++;
    }  else {
        state.part.inProcessDays--;
    }
    holidaysTotal(state.part);
    return updateObject( state, {
        part: state.part
    } );
};

const reducer = ( state = initialState, action: AnyAction  ) => {
    switch ( action.type ) {
        case actionTypes.HOLIDAYS_INIT: return holidaysInit( state, action );
        case actionTypes.PUT_HOLIDAYS_START: return putHolidaysStart( state, action );
        case actionTypes.PUT_HOLIDAYS_SUCCESS: return putHolidaysSuccess( state, action )
        case actionTypes.PUT_HOLIDAYS_FAIL: return putHolidaysFail( state, action );
        case actionTypes.FETCH_HOLIDAYS_START: return fetchHolidaysStart( state, action );
        case actionTypes.FETCH_HOLIDAYS_SUCCESS: return fetchHolidaysSuccess( state, action );
        case actionTypes.FETCH_HOLIDAYS_FAIL: return fetchHolidaysFail( state, action );
        case actionTypes.CHECK_HOLIDAY: return checkHoliday( state, action );
        default: return state;
    }
};

export default reducer;

const initHolidays = (employee: number, year: number) => {

    let ret = new HolidaysPart();
    ret.employee = employee;
    ret.year = year;
    ret.prevYear = year - 1;
    ret.holidays = [];

    ret.pendingDays = 0;
    ret.totalDays = 0;
    ret.enjoyedPrevYear = 0;
    ret.inProcessDays = 0;
    ret.enjoyed = 0;
    ret.enjoyedYear = 0;

    ret.manager = '';

    holidaysMonth(ret, "Enero", 0, year);
    holidaysMonth(ret, "Febrero", 1, year);
    holidaysMonth(ret, "Marzo", 2,  year);
    holidaysMonth(ret, "Abril", 3, year);
    holidaysMonth(ret, "Mayo", 4, year);
    holidaysMonth(ret, "Junio", 5, year);
    holidaysMonth(ret, "Julio", 6, year);
    holidaysMonth(ret, "Agosto", 7, year);
    holidaysMonth(ret, "Septiembre", 8, year);
    holidaysMonth(ret, "Octubre", 9, year);
    holidaysMonth(ret, "Noviembre", 10, year);
    holidaysMonth(ret, "Diciembre", 11, year);
    let curYear = new Date().getFullYear()  
    if (year === curYear)
    {
        holidaysMonth(ret, "Enero " + (year + 1), 0, year + 1);
    }

    return ret;
  }

  const holidaysMonth = (part: HolidaysPart, monthLabel: string, month: number, year: number) => {
    // Month days
    let first = new Date(year, month, 1);
    let last = new Date(year, month + 1, 0);
    let diasMes = [];
    let diasFinde = [];
    
    for (var j=first.getDate(); j<=last.getDate(); j++){
      var diaFD = new Date(year, month, j);
      if (diaFD.getDay() === 6 || diaFD.getDay() === 0){
        diasFinde.push(j);
      }
      diasMes.push(j);
    }

    let vacacion = new MonthHolidays();
    vacacion.total = 0;
    vacacion.days = [];
    vacacion.month = monthLabel;
    diasMes.forEach(
        () => vacacion.days.push(new Holiday(false))
    );
  
    for (let i = 0; i < vacacion.days.length; i++) {
      if (diasFinde.includes(i + 1)) {
        vacacion.days[i].weekend = true;
      }
    } 

    part.holidays.push(vacacion);
  }